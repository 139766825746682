:root {
  --max-width: 1200px;
  --border-radius: 5.58862px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
  --site-background-color: #000;

  --primary-color: #FAA507;
  --secondary-color: #F87C18;
  --grey-50: #EAEAEA;
  --grey-100: #A8A8A8;
  --grey-200: #868686;
  --grey-300: #8E8E8E;
  --grey-400: #2F2F2F;

  --text-color: #fff;
  --background-color: #181818;

  --search-form-padding: 3.75rem 3.75rem 0 3.75rem;
  --mobile-search-form-padding: 3.75rem 1.875rem 0 1.875rem;

  --page-padding: 3.75rem;

  --mobile-menu-height: 3.125rem;
  --mobile-page-padding: 1.625rem;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  color: var(--text-color);
  background: var(--site-background-color)
}

a {
  color: inherit;
  text-decoration: none;
}

h4 {
  color: var(--primary-color);
  font-weight: 800;
  font-size: 15px;
  line-height: 33px;
  letter-spacing: 0.634615px;
}

// Mui styling overrides
.MuiOption-root {

  &.MuiOption-highlighted,
  &.Mui-selected {
    background-color: #474747;
    color: #fff;
  }
}

.error {
  color: red;
}

.flex {
  display: flex;

  &.right {
    justify-content: flex-end;
  }

  &.left {
    justify-content: flex-start;
  }

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
  }

  &.space-around {
    justify-content: space-around;
  }

  &.align-center {
    align-items: center;
  }

  &.column {
    flex-direction: column;
  }
}

.gap1 {
  gap: 1rem;
}

.gap15 {
  gap: 1.5rem;
}

.gap2 {
  gap: 2rem;
}

.margin-top-1 {
  margin-top: 1rem;
}